import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Banner from 'components/banner'
import Meta from 'components/meta'
import Section from 'components/section'
import Wrapper from 'components/wrapper'

import { useImageFiles } from 'utils/use-image-files'
import { useSiteMetadata } from 'utils/use-site-metadata'

interface Props {
  location: Location
}

const Virtual = ({ location }: Props) => {
  const imageNode = (fileName) => {
    return useImageFiles().nodes.find((n) => n.relativePath == fileName)
  }
  const { siteTitle } = useSiteMetadata()
  return (
    <Wrapper location={location} className="w-100 mx-auto d-flex flex-column">
      <Meta title="Virtual Festival" />
      <Banner backgroundImage="adobe-355966925-banner_2560x320.jpg">
        <div
          className="d-none d-lg-flex flex-column justify-content-evenly align-items-center"
          style={{ minHeight: '240px' }}
        >
          <GatsbyImage
            backgroundColor="transparent"
            image={getImage(imageNode('nabla-wm-2line_315x150.png'))}
            alt="Nabla Festival logo"
          />
          <div className="banner-heading">Contemporary music, dance & intermedia</div>
        </div>
        <div
          className="p-3 d-none d-sm-flex d-lg-none d-flex flex-column justify-content-evenly align-items-center"
          aria-hidden="true"
        >
          <GatsbyImage
            backgroundColor="transparent"
            image={getImage(imageNode('nabla-wm-2line_210x100.png'))}
            alt="Nabla Festival logo"
          />
        </div>
        <div
          className="p-3 d-sm-none d-flex flex-column justify-content-evenly align-items-center"
          aria-hidden="true"
        >
          <GatsbyImage
            backgroundColor="transparent"
            image={getImage(imageNode('nabla-wm-2line_105x50.png'))}
            alt="Nabla Festival logo"
          />
        </div>
      </Banner>
      <Section className="py-3 text-center bg-body-dark">
        <div className="h4">NABLA VIRTUAL, PART ONE</div>
        <div className="h5 pb-2">December 18, 2020</div>
        <div className="pb-2 d-grid gap-2 d-flex justify-content-center">
          <a href="/virtual/stream-2020-12-18" className="btn btn-warning mx-3">
            STREAM
          </a>
          <a href="/download/programma-nabla-dicembre-2020.pdf" className="btn btn-primary mx-3">
            PROGRAM
          </a>
          <a href="/download/Nabla_Festival_December_2020.pdf" className="btn btn-info mx-3">
            POSTER
          </a>
        </div>
        <hr />
        <div className="h4">NABLA VIRTUAL, PART TWO</div>
        <div className="h5 pb-2">March 19, 2021</div>
        <div className="pb-2 d-grid gap-2 d-flex justify-content-center">
          <a href="/virtual/stream-2021-03-19" className="btn btn-warning mx-3">
            STREAM
          </a>
          <a href="/download/programma-nabla-marzo-2021.pdf" className="btn btn-primary mx-3">
            PROGRAM
          </a>
          <a href="/download/Nabla_Festival_March_2021.pdf" className="btn btn-info mx-3">
            POSTER
          </a>
        </div>
        <hr />
        <div className="h4">NABLA VIRTUAL, PART THREE</div>
        <div className="h5 pb-2">June 11, 2021</div>
        <div className="pb-2 d-grid gap-2 d-flex justify-content-center">
          <a href="/virtual/stream-2021-06-11" className="btn btn-warning mx-3">
            STREAM
          </a>
          <a href="/download/programma-nabla-giugno-2021.pdf" className="btn btn-primary mx-3">
            PROGRAM
          </a>
          <a href="/download/Nabla_Festival_June_2021.pdf" className="btn btn-info mx-3">
            POSTER
          </a>
        </div>
        <hr />
        <div className="h4">NABLA VIRTUAL, PART FOUR</div>
        <div className="h5 pb-2">September 17, 2021</div>
        <div className="pb-2 d-grid gap-2 d-flex justify-content-center">
          <a href="/virtual/stream-2021-09-17" className="btn btn-warning mx-3">
            STREAM
          </a>
          <a href="/download/programma-nabla-settembre-2021.pdf" className="btn btn-primary mx-3">
            PROGRAM
          </a>
          <a href="/download/Nabla_Festival_September_2021.pdf" className="btn btn-info mx-3">
            POSTER
          </a>
        </div>
      </Section>
    </Wrapper>
  )
}

export default Virtual
